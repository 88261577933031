<template>
  <header class="ui-kit-header">
    <div class="ui-kit-header__container">
      <div class="ui-kit-header__logo logo">
        <span class="ui-kit-header__logo-text">Nuxt 3</span>
      </div>
      <NuxtLink to="/ui-kit">
        <p class="ui-kit-header__title">
          UI-kit
        </p>
      </NuxtLink>
    </div>
  </header>
</template>

<style scoped lang="scss">
@import '../../../assets/styles/base/common/variables';

$root: '.mm-client-header';

.ui-kit-header {
  width: 100%;
  background-color: $text-white;
  padding-bottom: 10px;
  border-bottom: 2px solid $dark-gray;

  &__container {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1600px;
    margin: 0 auto;
    position: relative;
  }

  &__logo-wrapper {
    text-decoration: none;
    color: $black;
    width: 130px;
    display: flex;
    justify-content: space-between;
  }

  &__logo-icon {
    width: 25px;
  }

  &__logo-text {
    font-size: 20px;
  }

  &__title {
    font-size: 40px;
    position: absolute;
    left: 0;
    bottom: -15px;
    font-weight: 700;
    color: $black;
  }
}
@media screen and (max-width: 1280px) {
    .mm-client-header {
        &__title {
            left: 185px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .mm-client-header {
        &__title {
            left: 165px;
            bottom: 6px;
        }
    }
}

@media screen and (max-width: 1023px) {
  .mm-client-header {
    &__container {
      padding: 20px 20px 22px;
    }
  }
}
</style>
